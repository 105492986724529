import { Link } from "gatsby"
import React, { Component } from "react"
import { Language } from "../../store/reducers/utilsReducer"
import { localeContent } from "../../utils/locale"
import Button from "../base/Button"

interface Props {
  language: Language
}

export default class SubscribeAction extends Component<Props> {
  state = {
    email: "",
  }

  handleInputChange = (e: any) => {
    this.setState({
      [e.target.id]: e.target.value,
    })
  }

  render() {
    const language = this.props.language
    return (
      <div className="relative mt-24 sm:mt-32 sm:py-16">
        <div aria-hidden="true" className="hidden sm:block">
          <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl"></div>
          <svg
            className="absolute top-8 left-1/2 -ml-3"
            width="404"
            height="392"
            fill="none"
            viewBox="0 0 404 392"
          >
            <defs>
              <pattern
                id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="392"
              fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
            />
          </svg>
        </div>
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="relative rounded-2xl px-6 py-10 bg-arusgreen-500 overflow-hidden shadow-xl sm:px-12 sm:py-20">
            <div className="relative">
              <div className="sm:text-center">
                <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                  {localeContent(language).subscribeAction.title}
                </h2>
              </div>
              <form action="#" className="mt-12 sm:mx-auto sm:max-w-sm sm:flex">
                <Link to="/auth/signUp" className="min-w-0 flex-1">
                  <Button
                    className="w-full"
                    text={localeContent(language).subscribeAction.button}
                    type="dark"
                  />
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
