import { StarIcon } from "@heroicons/react/solid"
import React, { Component, ReactElement } from "react"
import { Language } from "../../store/reducers/utilsReducer"

interface Props {
  image: string
  title: string
  content: string
  language: Language
  callToAction?: ReactElement
  size?: string
}

export default class Hero extends Component<Props> {
  state = {
    email: "",
  }

  handleInputChange = (e: any) => {
    this.setState({
      [e.target.id]: e.target.value,
    })
  }

  renderRatingDescription = () => {
    if (this.props.language === Language.EN) {
      return (
        <>
          <span className="font-medium text-gray-900">Joined </span>
          by over
          <span className="font-medium text-arusgreen-500">
            {" "}
            2,000 families
          </span>
        </>
      )
    } else if (this.props.language === Language.BM) {
      return (
        <>
          <span className="font-medium text-gray-900">Disertai </span>
          oleh lebih daripada
          <span className="font-medium text-arusgreen-500">
            {" "}
            2,000 keluarga
          </span>
        </>
      )
    } else {
      return (
        <>
          <span className="font-medium text-gray-900">已加入</span>
          超过
          <span className="font-medium text-arusgreen-500"> 2,000 名家庭</span>
        </>
      )
    }
  }

  renderRating = () => {
    return (
      <div className="mt-6">
        <div className="inline-flex items-center divide-x divide-gray-300">
          <div className="flex-shrink-0 flex pr-5">
            <StarIcon className="h-5 w-5 text-yellow-400" />
            <StarIcon className="h-5 w-5 text-yellow-400" />
            <StarIcon className="h-5 w-5 text-yellow-400" />
            <StarIcon className="h-5 w-5 text-yellow-400" />
            <StarIcon className="h-5 w-5 text-yellow-400" />
          </div>

          <div className="min-w-0 flex-1 pl-5 py-1 text-sm text-gray-500 sm:py-3">
            {this.renderRatingDescription()}
          </div>
        </div>
      </div>
    )
  }

  render() {
    let contentClassName = ""
    let titleClassName = ""
    if (this.props.size === "small") {
      contentClassName =
        "mt-4 text-base leading-7 text-gray-500 whitespace-pre-line"
      titleClassName =
        "text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl"
    } else {
      contentClassName = "mt-6 text-xl text-gray-500 text-justify"
      titleClassName =
        "text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl"
    }
    return (
      <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
          <div>
            <div>
              <div className="mt-6 lg:mt-0 sm:max-w-xl">
                <h1 className={titleClassName}>{this.props.title}</h1>
                <p className={contentClassName}>{this.props.content}</p>
              </div>

              {this.props.callToAction && (
                <div className="mt-12 sm:max-w-lg sm:w-full sm:flex">
                  {this.props.callToAction}
                </div>
              )}
            </div>
            {this.renderRating()}
          </div>
        </div>

        <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div className="hidden sm:block">
              <div className="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full"></div>
              <svg
                className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                width="404"
                height="392"
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="392"
                  fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                />
              </svg>
            </div>
            <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
              <img
                className="w-8/12 rounded-md lg:h-full lg:w-auto lg:max-w-screen-sm object-cover"
                src={this.props.image}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
